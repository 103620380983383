import { loadComponents, removeComponents } from "../../../core/componentUtils"
import EventBus from '../../../core/EventBus'

export class NetteSnippetsExtension {
    initialize(naja) {
        naja.snippetHandler.addEventListener('beforeUpdate', this.handleBeforeUpdate)
        naja.snippetHandler.addEventListener('afterUpdate', this.handleAfterUpdate)
        this.timer = null
    }

    handleBeforeUpdate = event => {
        const { snippet, operation } = event.detail
        if (['prepend', 'append'].includes(operation.name)) {
            return
        }
        removeComponents(snippet)
    }

    handleAfterUpdate = async event => {
        const { snippet } = event.detail

        await loadComponents(snippet)

        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            EventBus.emit('dom:change', {
                element: event.detail.snippet
            })
        }, 0)
    }
}