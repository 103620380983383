export class SnippetHandler extends EventTarget {
    constructor(naja) {
        super()
        this.naja = naja
        this.op = {
            replace: (snippet, content) => {
                snippet.innerHTML = content
            },
            prepend: (snippet, content) => snippet.insertAdjacentHTML('afterbegin', content),
            append: (snippet, content) => snippet.insertAdjacentHTML('beforeend', content),
        }
        naja.addEventListener('success', (event) => {
            const { options, payload } = event.detail
            options.payload = payload

            if (payload.snippets) {
                this.updateSnippets(payload.snippets, false, options)
            }
        })
    }
    static findSnippets(predicate) {
        var _a
        const result = {}
        const snippets = window.document.querySelectorAll('[id^="snippet-"]')
        for (let i = 0; i < snippets.length; i++) {
            const snippet = snippets.item(i)
            if ((_a = predicate === null || predicate === void 0 ? void 0 : predicate(snippet)) !== null && _a !== void 0 ? _a : true) {
                result[snippet.id] = snippet.innerHTML
            }
        }
        return result
    }
    updateSnippets(snippets, fromCache = false, options = {}) {
        Object.keys(snippets).forEach((id) => {
            const snippet = document.getElementById(id)
            if (snippet) {
                this.updateSnippet(snippet, snippets[id], fromCache, options)
            }
        })
    }
    updateSnippet(snippet, content, fromCache, options) {
        let operation = this.op.replace

        if ((snippet.hasAttribute('data-naja-snippet-prepend') || snippet.hasAttribute('data-ajax-prepend')) || options.payload?.prepend === snippet.id && !fromCache) {
            operation = this.op.prepend
        }
        else if ((snippet.hasAttribute('data-naja-snippet-append') || snippet.hasAttribute('data-ajax-append')) || options.payload?.append === snippet.id && !fromCache) {
            operation = this.op.append
        }
        const canUpdate = this.dispatchEvent(new CustomEvent('beforeUpdate', {
            cancelable: true,
            detail: {
                snippet,
                content,
                fromCache,
                operation,
                changeOperation(value) {
                    operation = value
                },
                options,
            },
        }))
        if (!canUpdate) {
            return
        }
        if (snippet.tagName.toLowerCase() === 'title') {
            document.title = content
        }
        else {
            operation(snippet, content)
        }
        this.dispatchEvent(new CustomEvent('afterUpdate', {
            cancelable: true,
            detail: {
                snippet,
                content,
                fromCache,
                operation,
                options,
            },
        }))
    }
}