import { createLoading } from '../../../meta/loading'

// vytvari loading na elementech, ktery maji atribut [data-loading]
// 1. pokud byl najaAjax zavolany pres UiHandler, zacne se hledat [data-loading] od elementu, ktery UiHandler preda (typicky submit tlacitko u formulare nebo link)
// 2. pokud je manualne volany najaAjax (z autosubmitu, z giant komponenty), je potreba poslat do options 'element', od ktery se ma zacit hledat [data-loading]
//
// z bodu 1 nebo 2 mame vybrany element
//
// 1.USECASE [data-loading=""]
// - zacne se hledat pres .closest() neblizsi [data-loading]
// - byl nalezeny [data-loading] element a da se mu trida u-loading + se vytvori loader
//
// 2.USECASE [data-loading="<queryselector>"]
// - zacne se hledat pres .closest() neblizsi [data-loading]
// - byl nalezeny [data-loading=".myclass, #myform"] element, udela se queryselectorall na celym dokumentu a nalezenym elementum da tridu u-loading + na nich vytvori loader
//
// 3.USECASE [data-loading="prevent"]
// - prikladovy DOM: div>form.ajax[data-loading]>div>button[data-loading="prevent"]
// - najaAjax byl vyvolany klikem na submit button
// - zacne se hledat pres .closest() neblizsi [data-loading]
// - byl nalezeny [data-loading="prevent"] element => loader se NEVYTVORI (pokud by tam prevent nebyl, naslo by to form[data-loading] a vytvorily by se standardne loadery)
//
// 4.USECASE div#first[data-loading="#first,#second"] a div#second[data-loading="#third"] a div#third
// - najaAjax byl vyvolany na elementu #first (nebo nekde uvnitr neho)
// - zacne se hledat pres .closest() neblizsi [data-loading]
// - byl nalezeny div#first[data-loading="#first,#second"] element, loader se vytvori na div#first... div#second ma taky [data-loading], tak postup je stejny jako u USECASE1,USECASE2,USECASE3,USECASE4.
// - => pres #second se najde #third. #second NEBUDE mit zadny loader, na #third se vytvori loader

export class NetteLoadingExtension {
    initialize(naja) {
        naja.uiHandler.addEventListener('interaction', this.handleUiHandlerInteraction)
        naja.addEventListener('before', this.handleBefore);
        naja.addEventListener('start', this.handleStart)
        naja.addEventListener('complete', this.handleComplete)
    }


    handleBefore = event => {
        const loaderEl = event.detail.options.element?.closest('[data-loading]')
        if (loaderEl && loaderEl.dataset.loading !== 'prevent' && !event.detail.options.loading) {
            const loading = createLoading(loaderEl)
            event.detail.options.loading = loading
        }
    }

    handleUiHandlerInteraction = event => {
        const loaderEl = event.detail.element.closest('[data-loading]')
        if (loaderEl && loaderEl.dataset.loading !== 'prevent' && !event.detail.options.loading) {
            const loading = createLoading(loaderEl)
            event.detail.options.loading = loading
        }
    }

    handleStart = event => {
        const loading = event.detail.options.loading
        if (loading) {
            loading.show()
        }
    }

    handleComplete = event => {
        const loading = event.detail.options.loading
        if (loading) {
            loading.destroy()
        }
    }
}