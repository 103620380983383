import naja from '../../services/Naja'

export function najaAjax(options = {}) {
    options = {
        url: '',
        data: {},
        fetchOptions: {},
        najaOptions: {},
        dataType: 'json',
        beforeSend: () => { },
        before: () => { },
        success: () => { },
        error: () => { },
        complete: () => { },
        ...options
    }
    options.najaOptions = {
        uid: Math.random(),
        ...options.najaOptions,
    }
    options.fetchOptions = {
        method: options.method || 'GET',
        ...options.fetchOptions,
    }


    let controller = null
    let shouldAbort = false

    const handleStart = event => {
        const { options: _options } = event.detail
        if (_options.uid !== options.najaOptions.uid) {
            return
        }
        controller = event.detail.abortController
        if (shouldAbort) {
            controller.abort()
        }
        naja.removeEventListener('start', handleStart)
    }

    naja.addEventListener('start', handleStart)

    const run = async () => {
        try {
            options.before()
            options.beforeSend()
            const result = await naja.makeRequest(options.fetchOptions.method, options.url, options.data, options.najaOptions)
            options.success(result)
            return result
        } catch (error) {
            console.error(error)
            options.error()
        } finally {
            options.complete()
        }
    }

    const abort = () => {
        if (controller) {
            controller.abort()
        }
        shouldAbort = true
    }

    return {
        promise: run(),
        abort
    }
}