export function transformData(url, method, data) {
    const isGet = ['GET', 'HEAD'].includes(method.toUpperCase());
    // sending a form via GET -> serialize FormData into URL and return empty request body
    if (isGet && data instanceof FormData) {
        for (const [key, value] of data) {
            if (value !== null && value !== undefined) {
                url.searchParams.append(key, String(value));
            }
        }
        return null;
    }
    // sending a POJO -> serialize it recursively into URLSearchParams
    const isDataPojo = data !== null && Object.getPrototypeOf(data) === Object.prototype;
    if (isDataPojo || Array.isArray(data)) {
        // for GET requests, append values to URL and return empty request body
        // otherwise build `new URLSearchParams()` to act as the request body
        const transformedData = isGet ? url.searchParams : new URLSearchParams();
        for (const [key, value] of Object.entries(data)) {
            this.appendToQueryString(transformedData, key, value);
        }
        return isGet
            ? null
            : transformedData;
    }
    return data;
}