import EventBus from '../../../core/EventBus'

export class NetteEventsExtension {
    initialize(naja) {
        naja.addEventListener('start', this.handleStart)
        naja.addEventListener('success', this.handleSuccess)
        naja.addEventListener('complete', this.handleComplete)
    }

    handleStart = () => {
        EventBus.emit('nette:ajax:start')
    }

    handleSuccess = event => {
        const { component } = event.detail.payload
        if (component?.name) {
            EventBus.emit('nette:event', component)
        }
        EventBus.emit('nette:ajax:success')
    }

    handleComplete = () => {
        EventBus.emit('nette:ajax:complete')
    }
}