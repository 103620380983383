// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

import NajaService from './services/Naja'
NajaService.initialize()

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import ScrollService from './services/Scroll'
ScrollService.useNative()
setScrollPosition()

import { loadComponents } from './core/componentUtils'
loadComponents()

function setScrollPosition(x = null, y = null) {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'force' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}
