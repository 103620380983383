export class HistoryHandler extends EventTarget {
    constructor(naja) {
        super();
        this.naja = naja;
        this.href = null;
        this.popStateHandler = this.handlePopState.bind(this);
        naja.addEventListener('init', this.initialize.bind(this));
        naja.addEventListener('before', this.saveUrl.bind(this));
        naja.addEventListener('success', this.pushNewState.bind(this));
        naja.uiHandler.addEventListener('interaction', this.configureMode.bind(this));
        this.historyAdapter = {
            replaceState: (state, title, url) => window.history.replaceState(state, title, url),
            pushState: (state, title, url) => window.history.pushState(state, title, url),
        };
    }
    set uiCache(value) {
        console.warn('Naja: HistoryHandler.uiCache is deprecated, use options.snippetCache instead.');
        this.naja.defaultOptions.snippetCache = value;
    }
    initialize(event) {
        const { defaultOptions } = event.detail;
        window.addEventListener('popstate', this.popStateHandler);
        onDomReady(() => this.historyAdapter.replaceState(this.buildState(window.location.href, defaultOptions), window.document.title, window.location.href));
    }
    handlePopState(event) {
        const { state } = event;
        if (!state) {
            return;
        }
        const options = this.naja.prepareOptions();
        this.dispatchEvent(new CustomEvent('restoreState', { detail: { state, options } }));
    }
    saveUrl(event) {
        const { url } = event.detail;
        this.href = url;
    }
    configureMode(event) {
        var _a, _b, _c;
        const { element, options } = event.detail;
        // propagate mode to options
        if (!element) {
            return;
        }
        if (element.hasAttribute('data-naja-history') || ((_a = element.form) === null || _a === void 0 ? void 0 : _a.hasAttribute('data-naja-history'))) {
            const value = (_b = element.getAttribute('data-naja-history')) !== null && _b !== void 0 ? _b : (_c = element.form) === null || _c === void 0 ? void 0 : _c.getAttribute('data-naja-history');
            options.history = HistoryHandler.normalizeMode(value);
        }
    }
    static normalizeMode(mode) {
        if (mode === 'off' || mode === false) {
            return false;
        }
        else if (mode === 'replace') {
            return 'replace';
        }
        return true;
    }
    pushNewState(event) {
        const { payload, options } = event.detail;
        let mode = HistoryHandler.normalizeMode(options.history);
        if (mode === false || !payload.url) {
            return;
        }
        if (payload.url) {
            this.href = payload.url;
        }
        const method = mode === 'replace' ? 'replaceState' : 'pushState';
        this.historyAdapter[method](this.buildState(this.href, options), window.document.title, this.href);
        this.href = null;
    }
    buildState(href, options) {
        const state = { href };
        this.dispatchEvent(new CustomEvent('buildState', { detail: { state, options } }));
        return state;
    }
}

const onDomReady = (callback) => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', callback);
    }
    else {
        callback();
    }
};